var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "tBaseProject",
      attrs: { title: _vm.editTitle, "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "Form",
        {
          ref: "tBaseProjectForm",
          attrs: {
            model: _vm.tBaseProjectForm,
            rules: _vm.tBaseProjectFormRule,
            "label-width": 90,
          },
        },
        [
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "所属科室", prop: "officeId" } },
                    [
                      _c(
                        "Select",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请选择",
                            clearable: "",
                            "label-in-value": "",
                          },
                          on: { "on-change": _vm.onSectionChange },
                          model: {
                            value: _vm.tBaseProjectForm.officeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.tBaseProjectForm, "officeId", $$v)
                            },
                            expression: "tBaseProjectForm.officeId",
                          },
                        },
                        _vm._l(_vm.sectionList, function (item, i) {
                          return _c(
                            "Option",
                            {
                              key: item.id,
                              attrs: { value: item.id.toString() },
                            },
                            [_vm._v(" " + _vm._s(item.sectionName) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "项目代码", prop: "code" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入项目代码",
                        },
                        model: {
                          value: _vm.tBaseProjectForm.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.tBaseProjectForm, "code", $$v)
                          },
                          expression: "tBaseProjectForm.code",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "项目名称", prop: "name" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入名称",
                        },
                        model: {
                          value: _vm.tBaseProjectForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.tBaseProjectForm, "name", $$v)
                          },
                          expression: "tBaseProjectForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "项目简称", prop: "shortName" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入简称",
                        },
                        model: {
                          value: _vm.tBaseProjectForm.shortName,
                          callback: function ($$v) {
                            _vm.$set(_vm.tBaseProjectForm, "shortName", $$v)
                          },
                          expression: "tBaseProjectForm.shortName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "排序", prop: "orderNum" } },
                    [
                      _c("InputNumber", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          min: 1,
                          step: 1.1,
                          placeholder: "请输入排序",
                        },
                        model: {
                          value: _vm.tBaseProjectForm.orderNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.tBaseProjectForm, "orderNum", $$v)
                          },
                          expression: "tBaseProjectForm.orderNum",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "结果类型", prop: "resultType" } },
                    [
                      _c(
                        "Select",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.tBaseProjectForm.resultType,
                            callback: function ($$v) {
                              _vm.$set(_vm.tBaseProjectForm, "resultType", $$v)
                            },
                            expression: "tBaseProjectForm.resultType",
                          },
                        },
                        _vm._l(_vm.resultTypePriority, function (item, i) {
                          return _c(
                            "Option",
                            { key: item.value, attrs: { value: item.value } },
                            [_vm._v(" " + _vm._s(item.title) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "项目单位", prop: "unitCode" } },
                    [
                      _c(
                        "Select",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请选择",
                            clearable: "",
                            filterable: "",
                            "label-in-value": "",
                          },
                          on: { "on-change": _vm.onUnitChange },
                          model: {
                            value: _vm.tBaseProjectForm.unitCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.tBaseProjectForm, "unitCode", $$v)
                            },
                            expression: "tBaseProjectForm.unitCode",
                          },
                        },
                        _vm._l(_vm.unitPriority, function (item, i) {
                          return _c(
                            "Option",
                            { key: item.value, attrs: { value: item.value } },
                            [_vm._v(" " + _vm._s(item.title) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "默认值", prop: "defaultValue" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 200,
                          placeholder: "请输入默认值",
                        },
                        model: {
                          value: _vm.tBaseProjectForm.defaultValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.tBaseProjectForm, "defaultValue", $$v)
                          },
                          expression: "tBaseProjectForm.defaultValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "进入小结", prop: "inConclusion" } },
                    [
                      _c(
                        "Select",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请选择",
                            clearable: "",
                          },
                          model: {
                            value: _vm.tBaseProjectForm.inConclusion,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tBaseProjectForm,
                                "inConclusion",
                                $$v
                              )
                            },
                            expression: "tBaseProjectForm.inConclusion",
                          },
                        },
                        _vm._l(_vm.inConclusionPriority, function (item, i) {
                          return _c(
                            "Option",
                            { key: item.value, attrs: { value: item.value } },
                            [_vm._v(" " + _vm._s(item.title) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "进入报告", prop: "inReport" } },
                    [
                      _c(
                        "Select",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请选择",
                            clearable: "",
                          },
                          model: {
                            value: _vm.tBaseProjectForm.inReport,
                            callback: function ($$v) {
                              _vm.$set(_vm.tBaseProjectForm, "inReport", $$v)
                            },
                            expression: "tBaseProjectForm.inReport",
                          },
                        },
                        _vm._l(_vm.inReportPriority, function (item, i) {
                          return _c(
                            "Option",
                            { key: item.value, attrs: { value: item.value } },
                            [_vm._v(" " + _vm._s(item.title) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "LIS关联码", prop: "relationCode" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入LIS关联码",
                        },
                        model: {
                          value: _vm.tBaseProjectForm.relationCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.tBaseProjectForm, "relationCode", $$v)
                          },
                          expression: "tBaseProjectForm.relationCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          !_vm.disabled
            ? _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handelSubmit },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }